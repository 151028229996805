/**
 *
 * Login Page
 *
 *
 */

import React, { memo, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import Home from 'containers/Home';

import SEO from 'components/seo';
import theme from 'utils/theme';
import { ACCOUNT_ROUTE } from 'utils/routes';

const LoginPage = ({ user }) => {
  useEffect(() => {
    if (user) {
      navigate(ACCOUNT_ROUTE);
    }
  }, [user]);

  if (user) {
    return null;
  }
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <SEO title="Login" />
        <Home />
      </Container>
    </ThemeProvider>
  );
};
LoginPage.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ user }) => ({ user });
const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(LoginPage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;
